<template>
  <ion-page>
    <!-- Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="post-search" />
      <!-- Content Container -->
      <div class="app-max-width padding-right-md">
        <TextWrapper 
          :store="store.state"
          input-type="input"
          :is-mentions="false" 
          :is-hashtags="true" 
          :has-button="true" 
          set-delimeter="#"
          @input-data="getInputData" />        
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import ModalHeader from '../../components/ModalHeader.vue';
import { defineComponent } from 'vue';
import doAuth from '../../services/auth';
import TextWrapper from '../../components/TextWrapper.vue'
import { useStore } from "vuex";

export default defineComponent({
  name: 'PostSearch',
  props: [],
  components: { IonPage, IonContent, ModalHeader, TextWrapper },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    return {
      store
    }
  },
  methods: {
    // Only used on hashtag search so far
    getInputData: function(e) {
      console.log('search '+e)
    }, 
  }
});
</script>